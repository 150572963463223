.bl-logolist {
    @include breakpoint(large) {
        @include grid12;

        & > .linner {
            grid-column: 3 / span 8;
        }
    }

    .items {
        --itemsperrow: 2;
        @include breakpoint(medium) {
            --itemsperrow: 4;
        }
        --itemsgap: var(--layoutgridgap);
        @include flexitems;

        .ctrlogo {
            position: relative;
            background-color: $white;
            border:1px solid $lightbg;
            aspect-ratio: 2 / 1;
        }
        .logo {
            display: block;
            @include absposfull;
            border-style: solid;
            border-color:transparent;
            @include rem-calc-sl(border-width, 25, 0.5);

            box-sizing: border-box;
            object-fit: contain;
            object-position: center center;
        }
        .ctrtext {
            margin-top:0.3em;
        }
    }
}

//typo
.bl-logolist {
    h3 {
        @include font-size-pt(17, 17);
        font-weight: 400;
        line-height: 1.2;  
    }
}