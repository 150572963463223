.bl-quote {
    @include breakpoint(medium) {
        @include grid12;
        .linner {
            grid-column:2 / span 10;
        }
    }
    @include breakpoint(large) {
        .linner {
            grid-column:3 / span 8;
        }
    }
    .author {
        margin-top: 1em;
        margin-bottom: 1em;
        &::before {
            content:"–";
        }
    }
}

//typo
.bl-quote {
    .quote {
        @include typo-quote;
        color: $farbe1;
    }
}