//Standardabstand zwischen zwei Bloecken
.cblock + .cblock {
    @include rem-calc-sl(margin-top, 50, 0.5);
}

.lead0 .ctrtext {
    @include breakpoint(large) {
        padding-right: 20%; 
    }
} 

//Logik fuer Spacer
//(Vor und nach Spacer kein Abstand. Nur Abstand des Spacers selber.)
.cblock + .bl-spacer,
.bl-spacer + .cblock {
    margin-top:0;
}

//Spezifische Abstaende
.bl-title + .bl-imageslider {
    @include rem-calc-sl(margin-top, 60, 0.5);
}

:is(.bl-imageblock, .bl-textwithimage) + :is(.bl-imageblock, .bl-textwithimage) {
    @include rem-calc-sl(margin-top, 70, 0.5);
}

/*
//Abstand nach dem Titel
.bl-title + .cblock {
    @include rem-calc-sl(margin-top, 60, 0.5);
}
*/


