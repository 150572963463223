.bl-hero {
    position: relative;

    .overlay {
        position: absolute;
        background: linear-gradient(180deg, rgba(0,0,0,0) 30%, rgba(0,0,0,0.5) 100%);
        top: 0;
        bottom: 0;
        width: 100%;
        z-index: 1;
    }

    .ctrimage {
        img {
            @include w100bl;
        }
    }
    
    .ctrtext {
        position: absolute;
        @include rem-calc-sl(left, 140, math.div(20, 140));
        @include rem-calc-sl(right, 140, math.div(20, 140));
        @include rem-calc-sl(bottom, 140, math.div(60, 140));
        z-index: 3;
        .text {
            max-width:rem-calc(1200);
        }
    }

    .ctrbuttons {
        margin-top:1.25em;
    }

    @include breakpoint(large) {
        &.size-d-100vh .ctrimage img {
            object-fit: cover;
            object-position: center center;
            height:calc(100vh - var(--headerheight));
        }
        &.size-d-75vh .ctrimage img {
            object-fit: cover;
            object-position: center center;
            height:calc(0.75*(100vh - var(--headerheight)));
        }
        &.size-d-50vh .ctrimage img {
            object-fit: cover;
            object-position: center center;
            height:calc(0.5*(100vh - var(--headerheight)));
        }
    }
    @include breakpoint(medium down) {
        &.size-m-100vh .ctrimage img {
            object-fit: cover;
            object-position: center center;
            height:calc(100vh - var(--headerheight));
        }
        &.size-m-75vh .ctrimage img {
            object-fit: cover;
            object-position: center center;
            height:calc(0.75*(100vh - var(--headerheight)));
        }
        &.size-m-50vh .ctrimage img {
            object-fit: cover;
            object-position: center center;
            height:calc(0.5*(100vh - var(--headerheight)));
        }
    }
}

//typo
.bl-hero {
    .text {
        @include font-size-pt(35, 70);
        font-weight: 400;
        line-height:1.2; 
    }
    &.textcolor-light .text {
        color:$white;
        text-shadow: 0 0 8px rgba(0, 0, 0, .5), 0 0 12px rgba(0,0,0,.2);
    }
}