#desktopmenu {
    @media (max-width:1249px) {
        display: none;
    }
    
    .dropdownmenu {
        justify-content: flex-end;
    }

    li.level1 {
        position: relative; //allow right:0 on dropdown containers
    }
    li.level1.hassubmenu > ul { //Selector for dropdown container


        min-width: 7em;

        margin-top: 0.6em;
        margin-left:-0.5em;

        background-color: $white;

        &.opens-right {
            right:0;
        }
        
        a {
            padding:0.25em 0.5em;
            border-bottom:1px solid $lightbg;
        }
    }

    li.level1.hassubmenu > a {
        display:flex;
        align-items: center;
        gap:0.35em;
    }
    .icon-arrowdown {
        height:0.5em;
        transition:transform .2s;
    }
    li.level1.dropdownmenuopen .icon-arrowdown {
        transform:rotate(180deg);
    }
}

//typo
#desktopmenu {
    li.active > a {
        color:$farbe1;
    }
    li > a:hover {
        color:$farbe2;
    }

    font-size:rem-calc(18);
}