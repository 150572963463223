/*
.mmx-forms {
    $labelleft:rem-calc(14);

    @include breakpoint(medium) {
        .vf-row {
            @include grid;
            grid-template-columns: 1fr 1fr;
        }
        .sizespan2 {
            grid-column-end: span 2;
        }
    }
    @include breakpoint(small down) {
        .vf-element-layout + .vf-element-layout {
            margin-top:1em;
        }
    }

    .ctrsubmit {
        margin-left: auto;
    }

    input, textarea, select {
        border:1px solid $farbe1;
        //@include borderradius;
        font:inherit;
        padding:0.35em $labelleft 0.25em $labelleft;
        @include breakpoint(small down) {
            padding-top:0.55em;
            padding-bottom:0.45em;
        }

        width: 100%;
        box-sizing: border-box;

        outline:none;

        &::placeholder {
            color: transparent;
        }
        background-color: transparent;
        &:autofill {
            transition: all 100000s;
        }
        color:inherit;
    }
    textarea {
        height:7em;
    }

    .vf-element-layout-outer-wrapper {
        position: relative;
            
        label {
            position: absolute;
            left:$labelleft;
            top:0.2em;
            
            pointer-events: none;

            transition: all .2s;
            transform-origin: left top;

            color:$farbe1;
            font-size:0.75em;
        }

        &:focus-within,
        &:not(:has(input:placeholder-shown)):not(:has(textarea:placeholder-shown))
        {
            label {
                //left:1em;
                top:-0.6em;
                //transform: scale(0.5);
                background-color: var(--sectionbg);
            }
        }        
    }
}


.mmx-forms {
    .vf-element-error {
        color:$formerror;
        font-size:0.75em;
    }
}

*/


.mmx-forms {
    width: 700px;
    max-width: 100%;
    margin: 0 auto 2em auto;

.vf-row {

    input, textarea, select {
        border-radius: 2px;
        border: 2px solid $farbe1;
        box-shadow: none;
        &:focus {

        }
    }

    .vf-checkbox-text {
        margin: -5px 0 5px 3px;
    }

    .vf-input-group {
        border: none;
        box-shadow: none;
        outline: none;
    }

    .vf-input-group-focused {
        border: none;
    }
}



    button.vf-btn.vf-btn-primary {
        @extend .button;
    }
}