.bl-textwithimage {
    .colimage img {
        @include w100bl;
    }
    @include breakpoint(large) {
        .cols {
            @include grid12;
            @include gridrow1;
        }

        &.imgposr {
            .colimage {
                grid-column: 6 / span 7;
            }
            .coltext {
                grid-column: 2 / span 4;
            }
        }
        &.imgposl {
            .colimage {
                grid-column: 1 / span 7;
            }
            .coltext {
                grid-column: 8 / span 4;
            }
        }
    }
    @include breakpoint(medium down) {
        .coltext {
            margin-top:0.4em;
        }
    }
}

//typo
.bl-textwithimage {
    @include contenttable;
}