@mixin typo-base {
  //Dynamic Font Size (mobile, desktop)
  @include font-size-pt(20, 22);
  font-weight: 400;
  letter-spacing: 0em;
  line-height:math.div(31, 22);
}

body {
  font-family:'Barlow', sans-serif;
  @include typo-base;
  -moz-osx-font-smoothing: grayscale;
  color:$textcolor;
}
a {
  color:inherit;
  text-decoration: none;
}
a:focus {
  outline: none;
}

@mixin typo-h1 {
  @include font-size-pt(26, 45);
  font-weight: 700;
  line-height: math.div(63, 45);
  color:$farbe1;
}
@mixin typo-h2 {
  @include font-size-pt(26, 35);
  font-weight: 700;
  line-height: math.div(45, 35);
  color:$farbe1;
}
@mixin typo-h3 {
  font:inherit;
  font-weight: 700;
}
@mixin typo-h4 {
  @include typo-h3;
}

@mixin typo-lead {
  @include font-size-pt(28*0.75, 28);
  font-weight: 400;
  letter-spacing: 0em;
  line-height:math.div(37, 28);
}
@mixin typo-quote {
  font-family: 'Noto Serif', serif;
  @include font-size-pt(60*0.5, 60);
  font-weight: 400;
  letter-spacing: 0em;
  line-height: math.div(70, 50);
}
@mixin typo-footer {
  @include font-size-pt(18*0.75, 18);
  font-weight: 400;
  letter-spacing: 0em;
  line-height:math.div(22, 18);
}
@mixin typo-menu {

}

strong {
  font-weight: 700;
}

address {
  font:unset; //remove italic browser style
}

p {
  margin:0 0 1em 0;
}
p:last-child {
  margin-bottom:0;
}

h1, h2 {
  margin:0 0 0.75em 0;
}
h3, h4 {
  margin:0;
}
h1 {
  @include typo-h1;
}
h2 {
  @include typo-h2;
}
h3 {
  @include typo-h3;
}
h4 {
  @include typo-h4;
}

@mixin contenttable {
  table {
    border-collapse:collapse;
  }
  th, td {
    border:1px solid $lightbg;
    padding:0.2em 0.5em;
  }
}

/*
@mixin text-decoration-underline {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 0.1em;
}
*/