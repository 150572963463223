.bl-personcard {
    @include breakpoint(large) {
        @include grid12;

        .ctrcard {
            grid-column: 3 / span 8;
            @include breakpoint(xlarge) {
                grid-column: 4 / span 6;
            }
        }
    }

    .ctrcard {
        //background-color: $lightbg;

        .ctrimage img {
            @include w100bl;
            border-radius: 9999px;
            max-width:rem-calc(200);
        }

        @include breakpoint(medium) {
            @include rem-calc-sl(padding-top, 70, 0.5);
            @include rem-calc-sl(padding-right, 46, 0.5);
            @include rem-calc-sl(padding-left, 46, 0.5);
            @include rem-calc-sl(padding-bottom, 44, 0.5);

            @include grid;
            grid-template-columns: 1fr 3fr;
        }
        @include breakpoint(small down) {
            padding-left: 0;
            padding-right: 0;
            padding-top:rem-calc(40);
            padding-bottom:rem-calc(60);

            @include grid;
            grid-template-columns: repeat(6, 1fr);
            .ctrimage, .ctrinfo {
                grid-column: 2 / span 4;
            }
            .ctrimage img {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}

//typo
.bl-personcard {
    .ctrinfo {
        a {
            color:$farbe1;
        }
    }
}