.bl-teamlist {
    .items {
        @include grid;
        @include breakpoint(large) {
            grid-template-columns: repeat(4, 1fr);
        }
        @include breakpoint(medium down) {
            grid-template-columns: repeat(3, 1fr);
        }
        @include breakpoint(small down) {
            grid-template-columns: repeat(1, 1fr);
        }

        .ctrimage {
            img {
                @include w100bl;
            }
        }
        .ctrtext {
            background-color: $white;
            @include rem-calc-sl(padding-top, 30, 0.5);
            @include rem-calc-sl(padding-bottom, 30, 0.5);
            @include rem-calc-sl(padding-left, 20, 0.5);
            @include rem-calc-sl(padding-right, 20, 0.5);

            .info {
                margin-top:0.5em;
            }

            a {
                color:$farbe1;
            }
        }
    }
}

//typo
.bl-teamlist {
    h3 {
        @include font-size-pt(20, 22);
        font-weight: 700;
        line-height: math.div(22, 20);
        color:$farbe1;
        margin-bottom: 0.5em;
    }
    .ctrtext {
        @include font-size-pt(17, 17);
        font-weight: 400;
        line-height: math.div(24, 17);
    }
}

//Farbige Boxen, falls Section weiss
section.bgdefault .bl-teamlist .ctrtext {
    background-color: $lightbg;
}