#mobilemenu {
    background-color: $white;
    color:$black;

    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height:100%;

    z-index: 100;

    box-sizing: border-box;
    padding-top:rem-calc(120);
    padding-left:var(--lrminmargin);
    padding-right:var(--lrminmargin);
    padding-bottom:rem-calc(20);


    ul a {
        padding:rem-calc(10) 0;
        border-top:1px solid $lightgray;
    }
    li.level2 a {
        padding-left:1em;
    }
    li.hassubmenu > a {
        display:flex;
        justify-content: space-between;
        align-items: center;

        + ul {
            margin-bottom: rem-calc(20);
            a {
                border: none;
                font-size: 0.95em;
                padding-bottom: 0;
            }
        }
    }
    .icon-arrowdown {
        height:rem-calc(18);
        transition: transform .2s;
    }
    a.expanded .icon-arrowdown {
        transform:rotate(180deg);
    }

    li.active > a {
        color:$farbe1;
    }
}

#mobilemenu {
    transition:transform .4s;
}
#mobilemenu:not(.visible) {
    pointer-events: none;
    transform:translateX(-100%);
}


#hamburger-mobilemenu {
    @media (min-width:1250px) {
        display: none;
    }

    position:fixed;
    right:var(--lrminmargin);
    z-index:200;

    //Spaeter mit anchor positioning loesen
    //https://caniuse.com/css-anchor-positioning
    top:rem-calc(60);
    transition:top .2s;
    @include breakpoint(medium down) {
        top:rem-calc(50);
    }
    @include breakpoint(small down) {
        top:rem-calc(35);
    }
    body:has(header.small) & {
        @include breakpoint(large) {
            top:rem-calc(30);
        }
    }

    //Andere Hamburgerfarbe wenn mobiles Menu sichtbar
    /*
    .mobilemenuvisible & {
        --hbnormal:#fff;
        --hbhover:#fff;
    }
    */
}


#mobilemenu {
    transition:opacity .4s;
    .ctrmenu {
        transition: transform .4s;

        border-bottom: 1px solid $lightgray;
    }
}
#mobilemenu:not(.visible) { 
    pointer-events: none;
    opacity:0;
    .ctrmenu {
        transform: translateY(50px);
    }
}
